import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './locales'
import pinia from './stores'

import '@/assets/style/reset.scss'
import '@/assets/style/common.scss'

import 'element-plus/es/components/message/style/css'

// ResizeObserver loop completed with undelivered notifications
import { debounce } from "lodash";
const resizeObserver  = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends resizeObserver  {
    constructor(callback: (...args: any[]) => void) {
        callback = debounce(callback, 100);
        super(callback);
    }
};

createApp(App)
  .use(router)
  .use(pinia)
  .use(i18n)
  .mount('#app')
