import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { useSelectionStore } from '@/stores/selection'
import { useUserStore } from '@/stores/user'
import { redirect2PreloadPageIfNeed } from '@/mixins/preload'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/preload',
    name: 'preload',
    component: () => import(/* webpackChunkName: "preload" */ '@/views/loading/loading-preload.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/main.vue')
  },
  {
    path: '/selection',
    name: 'selection',
    redirect: '/selection/stepPoolShape',
    component: () => import(/* webpackChunkName: "selection" */ '@/views/selection/main.vue'),
    children: [
      {
        path: 'stepPoolShape',
        component: () => import(/* webpackChunkName: "step-pool-shape" */ '@/views/selection/selection-step-pool-shape.vue'),
        meta: {
          auth: true,
        }
      },
      {
        path: 'otherInfo',
        component: () => import(/* webpackChunkName: "step-other-info" */ '@/views/selection/selection-step-other-info.vue'),
        meta: {
          auth: true,
        }
      },
      {
        path: 'forYou',
        component: () => import(/* webpackChunkName: "step-for-you" */ '@/views/selection/selection-step-for-you.vue'),
        meta: {
          auth: true,
        }
      },
    ]
  },
  {
    path: '/report',
    name: 'report',
    component: () => import(/* webpackChunkName: "report" */ '@/views/report/main.vue'),
    meta: {
      auth: true,
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/login'
  }
]


if (process.env.NODE_ENV === 'development') {
  routes.push(
    {
      path: '/management',
      name: 'management',
      redirect: '/management/product',
      component: () => import(/* webpackChunkName: "management" */ '@/views/management/main.vue'),
      children: [
        {
          path: 'product',
          name: 'managementProduct',
          component: () => import(/* webpackChunkName: "report" */ '@/views/management/manage-product.vue')
        },
      ]
    }
  )
}


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const pushTj = () => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (JmTongJi) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      JmTongJi.referrer = window.location.href;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      JmTongJi.isVueFirstLoad && JmTongJi.sendleave();
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        JmTongJi.collect('');
      }, 200)
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

router.beforeEach((to, from, next) => {

  {
    const { routeChecker } = useUserStore();
    const { isValidate, redirectPath } = routeChecker(to);
    if (!isValidate) {
      next(redirectPath);
      pushTj();
      return;
    }
  }

  {
    const { isNext, redirectPath } = redirect2PreloadPageIfNeed(to)
    if (!isNext) {
      next(redirectPath);
      pushTj();
      return;
    }
  }

  // {
  //   const { routeChecker } = useSelectionStore();
  //   const { isValidate, redirectPath } = routeChecker(to);
  //   if (!isValidate) {
  //     next(redirectPath);
  //     return;
  //   }
  // }
  pushTj();
  next();
})

export default router
